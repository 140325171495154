$flamapink: #e80058;
$flamayellow: #e8bd00;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "PT Sans", sans-serif;
}

.favicon {
  max-height: 35px;
}

.text-gradient {
  background: linear-gradient(90deg, $flamapink 0%, $flamayellow 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient {
  background: linear-gradient(90deg, $flamapink 0%, $flamayellow 100%);
}

#main-menu {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  /*Create a horizontal list with spacing*/
  li {
    display: inline-block;
    margin-left: 1em;
    text-transform: uppercase;
    font-size: 0.9rem;
  }

  /*Style 'show menu' label button and hide it by default*/
  .show-menu {
    text-decoration: none;
    display: none;
  }
  #show-menu-label {
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath fill='%23000' d='M1,4 H18 V6 H1 V4 M1,9 H18 V11 H1 V7 M3,14 H18 V16 H1 V14' /%3E%3C/svg%3E");
  }
  /*Hide checkbox*/
  #btn-close,
  input[type="checkbox"] {
    display: none;
  }
  /*Show menu when invisible checkbox is checked*/
  input[type="checkbox"]:checked ~ #menu {
    display: block;
  }
  input[type="checkbox"]:checked ~ #show-menu-label {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' enable-background='new 0 0 40 40'%3E%3Cline x1='15' y1='15' x2='25' y2='25' stroke='%23000' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10'%3E%3C/line%3E%3Cline x1='25' y1='15' x2='15' y2='25' stroke='%23000' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10'%3E%3C/line%3E%3Ccircle class='circle' cx='20' cy='20' r='19' opacity='0' stroke='%23000' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' fill='none'%3E%3C/circle%3E%3Cpath d='M20 1c10.45 0 19 8.55 19 19s-8.55 19-19 19-19-8.55-19-19 8.55-19 19-19z' class='progress' stroke='%23000' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' fill='none'%3E%3C/path%3E%3C/svg%3E");
  }
}

// some parallax
// end parallax

.btn-all,
.web .btn-web,
.design .btn-design,
.mix .btn-mix {
  background-color: rgb(31 41 55);
}

.web .btn-all,
.design .btn-all,
.mix .btn-all {
  background-color: $flamapink !important;
}

.web .design,
.web .mix,
.design .web,
.design .mix,
.mix .web,
.mix .design {
  display: none;
}

#portfolio {
  min-height: 2030px;
}

.portfolio-box {
  position: relative;
  overflow: hidden;

  .hover-text {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    color: #ffffff;
    background: linear-gradient(180deg, #e80058 0%, #e8bd00 100%);
    padding: 25% 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 0.5s;

    h4 {
      transform: translateY(0);
      transition: all 0.3s;
    }
  }
}

.embedsocial-hashtag {
  padding-top: 15px;
}

#moreAbout {
  position: relative;
  .shape {
    background: url("img/gplay.png") repeat 0 0;
    background-color: rgb(31 41 55);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    transform-origin: 100% 0;
    transform: skewY(5deg);
    overflow: hidden;
  }
}

/*Responsive Styles*/
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

// Mobile
@media screen and (max-width: 760px) {
  #moreAbout .shape {
    height: 106%;
  }

  #main-menu {
    /*Make dropdown links appear inline*/
    ul {
      display: none;
      position: absolute;
      top: 3em;
      left: 0;
      margin-top: 1px;
      background-color: #fff;
      border-bottom: solid 1px rgb(209, 213, 219);
    }
    /*Create vertical spacing*/
    li {
      font-size: 1rem;
    }
    /*Make all menu links full width*/
    ul li,
    li a {
      margin: 0;
      width: 100%;
    }
    li a {
      display: inline-block;
      padding: 1em 1em 0.5em;
      font-weight: bold;
    }
    li:last-child a {
      padding-bottom: 1em;
    }
    /*Display 'show menu' link*/
    .show-menu {
      display: block;
    }
  }
}

// Desktop
@media screen and (min-width: 1025px) {
  .portfolio-box:hover .hover-text {
    opacity: 1;
    transition: all 0.5s;

    h4 {
      transform: translateY(10%);
      transition: all 0.3s;
      font-size: 35px;
    }
  }
}
@media screen and (min-width: 1280px) {
  #moreAbout .shape {
    height: 140%;
  }
}
@media screen and (min-width: 1440px) {
  #moreAbout .shape {
    height: 158%;
  }
}
